
/* @flow */

import type { Mode } from "state/view-mode";

import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { CartMini } from "@crossroads/ui-components";
import cn from "classnames";
import SearchMenu from "components/AppHeader/SearchMenu";
import ChevronIcon from "icons/chevron-mini.svg";
import Categories from "components/AppHeader/Large/Categories";
import CartCounter from "components/AppHeader/CartCounter";

import { MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  mode: Mode,
  setMode: Mode => void,
};

const Shop = ({ mode, setMode }: Props): React$Node => {
  const toggleCategories = () => setMode(mode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const { categories, routes } = useContext(StoreInfoContext);
  const { location: { pathname } } = useHistory();
  const quoteData = useData(QuoteData);
  const t = useTranslate();

  return (
    <>
      {(routes.allProductsView && routes.allProductsView.url &&
        routes.allProductsView.toggle !== undefined && routes.allProductsView.toggle) && (
        <NavLink
          to={routes.allProductsView.url}
          activeClassName={styles.active}
          className={styles.item}
        >
          {routes.allProductsView.title}
        </NavLink>
      )}

      <span
        className={
          cn(
            styles.item,
            styles.item__categories,
            { [styles.active]: mode === MODE.CATEGORIES }
          )
        }
        onClick={toggleCategories}
      >
        {(routes.categoriesView && routes.categoriesView.title) ?? ""}
        <ChevronIcon className={styles.chevron} />

        {mode === MODE.CATEGORIES &&
        <nav className={styles.categories}>
          <Categories categories={categories} />
        </nav>
        }

      </span>

      {routes.brandsView && routes.brandsView.url && routes.brandsView.title &&
        <Link
          className={
            cn(
              styles.item,
              styles.item__categories,
              styles.brands
            )
          }
          to={{
            pathname: routes.brandsView?.url,
          }}
        >
          {routes.brandsView?.title}
        </Link>
      }

      <span
        className={
          cn(
            styles.item,
            styles.item__search
          )
        }
      >
        <nav className={styles.search} style={{ width: "280px" }}>
          <SearchMenu autoFocus />
        </nav>
      </span>
      {!pathname.includes("/checkout/") && (
        <div className={styles.item__cart}>
          <div className={styles.counter}>
            <CartCounter
              className={cn(styles.item, { [styles.active]: mode === MODE.CART })}
              isOpen={mode === MODE.CART}
              openMiniCart={() => setMode(MODE.CART)}
            >
              <p>{t("CART.TITLE")}</p>
            </CartCounter>
          </div>
          {mode === MODE.CART && routes.checkoutView && routes.checkoutView.url && (
            <CartMini
              quoteData={quoteData}
              variant="b2b"
              altUrl={`${routes.checkoutView.url}/cart`}
              ctaUrl={`${routes.checkoutView.url}/shipping`}
              className={cn(styles.desktop, styles.miniCart)}
              onClose={() => setMode(MODE.NORMAL)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Shop;
