/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React, { useState, useMemo, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import ProductList from "components/ProductList";
import ProductCarousel from "components/ProductCarousel";
import { parseParams } from "helpers/location-search-string";
import { getCategoryMeta } from "helpers/get-meta";
import { useTranslate } from "@awardit/react-use-translate";
import { useFilter, OffCanvasFilterMenu, ActiveFilters } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import PaginatedProductList from "./paginated-product-list";
import Filterbar from "components/Filterbar";
import { load as loadRoute } from "@crossroads/shop-state/route";
import { useData, useSendMessage } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { RouteData } from "data";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { constructSortableValues } from "helpers/filters";
import { PAGE_SIZE } from "effects/route";
import cn from "classnames";

import styles from "./styles.scss";

type CategoryProps = {
  category: Category,
  location: Location,
};

type HintCategoryProps = {
  category: HintCategory,
};

// TODO: use Pagination instead on server
const CategoryView = ({ category, location }: CategoryProps): React$Node => {
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const meta = getCategoryMeta(category);
  const route = useData(RouteData);
  const updating = route.state === "UPDATING";
  const params = useMemo(() => parseParams(location.search), [location.search]);
  const openFiltermenu = useOpenFiltermenu();
  const { content: { productCarousel: { useOnCategoryView } },
    routes: { allProductsView }, categories } = useContext(StoreInfoContext);
  const allProductsViewTitle = allProductsView && allProductsView.title ? allProductsView.title : "";
  const allProductsViewLink = allProductsView && allProductsView.url ? allProductsView.url : "";
  const parentLink = { name: allProductsViewTitle, url: allProductsViewLink };
  const nonLinearSlider = true;

  /** Only show productCarousel with category data if it is toplevel category
  since data in Infobaleen/Siftlab is restricted to top level atm */
  const isSubCategory = Boolean(categories.find(array => {
    return array.children.some(item => {
      return item.url === location.pathname;
    });
  }));

  const [page, setPage] = useState(() => Math.max(1, parseInt(params.page, 10) || 1));

  const useFilterStateConfig = {
    loading: updating,
    productList: category.products,
    usePoints: true,
    incVat: false,
    load: location => {
      sendMessage(loadRoute(location, false));
    },
  };

  const filterState = useFilter(useFilterStateConfig);

  const numPages = Math.ceil(category.products.totalCount / PAGE_SIZE);

  // Update page when URL is updated
  useEffect(() => {
    setPage(parseInt(params.page, 10) || 1);
  }, [params]);

  return (
    <Wrapper className="awardit-categoryWrapper">
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      <Breadcrumbs parentLink={parentLink} current={category.name} />

      {(Boolean(useOnCategoryView) && !isSubCategory && page === 1) && (
        <ProductCarousel className={styles.recommendedProducts} variant="CATEGORY" category={category.name} />
      )}

      <div className={styles.header}>
        <Title>{category.name}</Title>
        <p className={styles.total_count}>{t("CATEGORY.TOTAL_COUNT", { total: category.products.totalCount })}</p>
      </div>

      <div className={styles.activeFiltersRow}>
        {filterState.active.filters.length > 0 &&
          <h2 className={styles.activeFiltersRowHeader}>
            {t("FILTER.ACTIVE_FILTERS.COUNT")}
            <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
          </h2>
        }
        <ActiveFilters
          hideEmptyFilters
          filterState={filterState}
          className={cn(styles.activeFilters, "awardit-activeFilters")}
        />
      </div>

      <Filterbar
        openFilters={openFiltermenu.openFiltermenu}
        className={styles.filterbar}
        filterState={{
          ...filterState,
          sort: {
            ...filterState.sort,
            values: Array.isArray(filterState.sort.values) ?
              constructSortableValues(filterState.sort.values, t) :
              [],
          },
        }} />

      <PaginatedProductList
        updating={updating}
        page={page}
        numPages={numPages}
        perPage={PAGE_SIZE}
        productList={category.products}
        category={category.name}
      />

      <OffCanvasFilterMenu
        isOpen={openFiltermenu.isOpen}
        close={openFiltermenu.closeFiltermenu}
        filterState={filterState}
        nonLinearSlider={nonLinearSlider}
        exponentiation={4}
        containerClass="awardit-OffCanvasFilterMenu"
      />
    </Wrapper>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps): React$Node => {
  const { routes: { allProductsView } } = useContext(StoreInfoContext);
  const allProductsViewTitle = allProductsView && allProductsView.title ? allProductsView.title : "";
  const allProductsViewLink = allProductsView && allProductsView.url ? allProductsView.url : "";
  const parentLink = { name: allProductsViewTitle, url: allProductsViewLink };

  return (
    <Wrapper className="awardit-categoryWrapper">
      <Breadcrumbs parentLink={parentLink} current={category.name} />

      <h1>{category.name}</h1>

      <ProductList products={[null, null, null, null, null, null, null, null]} />
    </Wrapper>
  );
};

export default CategoryView;
