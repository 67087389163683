/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import SystemMessages from "components/SystemMessages";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";
import useIsSmall from "helpers/use-is-small";
import { getFavicons } from "helpers/get-meta";
import CookieConsent from "components/CookieConsent";
import { CmsData } from "data";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  location: Location,
};

const GuestApp = ({ children, location }: Props): React$Node => {
  const {
    info,
    configuration,
    routes,
    content: { loginview },
  } = useContext(StoreInfoContext);
  const isSmall = useIsSmall(parseInt(styles.small, 10));

  const style = {
    background: loginview.imageBackground ? `url(${loginview.imageBackground})` : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
  };

  const loginViewLogoSrc = loginview.logo || "";

  return (
    <CmsData.Provider url="cookie-consent">
      <div className={styles.block} style={style}>
        <Helmet
          titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
          link={getFavicons(configuration)}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <header
          className={styles.header}
          style={loginview.formOpacity ? { backgroundColor: `rgba(255, 255, 255, ${loginview.formOpacity})` } : {}}
        >
          <Wrapper className={cn(
            { [styles.termsHeader]: location.pathname === routes.termsView?.url },
            styles.headerWrapper
          )}
          >
            <Link to="/">
              <Logo
                width={isSmall ? configuration.logoWidthSmall :
                  configuration.logoWidthLarge}
                loginViewLogo={loginViewLogoSrc}
              />
            </Link>
          </Wrapper>
        </header>
        <div className={styles.wrapper}>
          {children}
          <SystemMessages />
          {location.pathname === routes.loginView?.url &&
            <div>
              <CookieConsent />
            </div>
          }
        </div>
      </div>
    </CmsData.Provider>
  );
};

export default GuestApp;
