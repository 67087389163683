/* @flow */

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { SubNavHeader } from "components/AppHeader/Small/SubNav";

import styles from "./styles.scss";

type Props = {
  closeHamburger: () => void,
  setSubNavOpen: boolean => void,
};

const Earn = ({
  closeHamburger,
  setSubNavOpen,
}: Props): React$Element<"nav"> => {
  const t = useTranslate();
  const { routes, content: { earnview } } = useContext(StoreInfoContext);

  return (
    <nav className={cn(styles.block)}>
      <SubNavHeader
        className="awardit-appHeaderSmallSubNavHeader"
        onBack={() => setSubNavOpen(false)}
        onClose={closeHamburger}
      >
        {(routes.earnView && routes.earnView.title) ?? ""}
      </SubNavHeader>
      {(routes.allProductsView && routes.allProductsView.toggle !== undefined &&
        routes.allProductsView.toggle) && (
        <div className="awardit-appHeaderSmallSubNavItems">
          {routes.earnView?.url &&
            <NavLink
              to={routes.earnView.url}
              className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
            >
              {t("EARN.START")}
            </NavLink>
          }
        </div>
      )}
      {earnview.cardLinkTitle1 && earnview.cardLinkUrl1 && (
        <div className="awardit-appHeaderSmallSubNavItems">
          <NavLink
            to={earnview.cardLinkUrl1}
            className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          >
            {earnview.cardLinkTitle1}
          </NavLink>
        </div>
      )}
      {earnview.cardLinkTitle2 && earnview.cardLinkUrl2 && (
        <div className="awardit-appHeaderSmallSubNavItems">
          <NavLink
            to={earnview.cardLinkUrl2}
            className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          >
            {earnview.cardLinkTitle2}
          </NavLink>
        </div>
      )}
      {earnview.cardLinkTitle3 && earnview.cardLinkUrl3 && (
        <div className="awardit-appHeaderSmallSubNavItems">
          <NavLink
            to={earnview.cardLinkUrl3}
            className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          >
            {earnview.cardLinkTitle3}
          </NavLink>
        </div>
      )}
      {earnview.cardLinkTitle4 && earnview.cardLinkUrl4 && (
        <div className="awardit-appHeaderSmallSubNavItems">
          <NavLink
            to={earnview.cardLinkUrl4}
            className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          >
            {earnview.cardLinkTitle4}
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default Earn;
