/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { Link } from "react-router-dom";

import CardIcon from "./svg/credit-card-solid.svg";
import CapIcon from "./svg/graduation-cap-solid.svg";
import QuestionIcon from "./svg/question-solid.svg";

import styles from "./styles.scss";

const AboutView = (): React$Node => {
  return (
    <div className={styles.block}>
      <Helmet title="Om Team Rexel" />

      <div className={styles.fullWidth__Top}>
        <div className={styles.introField}>
          <h1>Det är poängen som är poängen</h1>
          <p>Team Rexel är vår kundklubb där vi ger dig rikligt med erbjudanden
            och hjälper dig och ditt företag att utvecklas.
            Klubben riktar sig till små och medelstora installatörsfirmor.
          </p>
        </div>
      </div>
      <Wrapper className={styles.wrapper}>
        <div className={styles.cardsField}>
          <h1>Så samlar du poäng</h1>
          <div className={styles.cards}>
            <div className={styles.card}>
              <div className={styles.icon}>
                <div className={styles.container}>
                  <CardIcon />
                </div>
              </div>
              <h3>Inköp</h3>
              <p>0,2 poäng per inköpskrona*.</p>
              <a href="#purchase" className={styles.cardBtn}>Läs mer</a>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}>
                <div className={styles.container}>
                  <CapIcon />
                </div>
              </div>
              <h3>Utbildning</h3>
              <p>0,2 poäng per fakturerad krona.</p>
              <a href="#education" className={styles.cardBtn}>Läs mer</a>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}>
                <div className={styles.container}>
                  <QuestionIcon />
                </div>
              </div>
              <h3>Quiz</h3>
              <p>Tjäna extra poäng på quizzar</p>
              <a href="#quiz" className={styles.cardBtn}>Läs mer</a>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className={styles.fullWidth__Bottom}>
        <div className={styles.pointsContainer}>
          <img className={styles.imgRight} src="/assets/rexel/rexel-about.jpeg" />
          <div className={styles.pointsField}>
            <h3>Och sen då?</h3>
            <h1>Byt poängen mot premier</h1>
            <p>
              I Premieshoppen finns produkter av hög kvalitet för alla intressen och smaker.
              Ni hittar exempelvis roliga evenemang och resor, klockor, surfplattor,
              parfym, verktyg, grillar och mycket annat. Du betalar med dina poäng,
              enkelt och smidigt. <Link to="/all-products">Kika in i shoppen</Link> och hitta dina favoriter!
            </p>
            <Button className={styles.pointsBtn} variant="primary" to="/">
              Lös in poängen i premieshoppen
            </Button>
            <img src="/assets/rexel/rexel-about-brands.jpeg" />
          </div>
        </div>
      </div>
      <Wrapper className={styles.bottomBlocksWrapper}>
        <div id="info" />
        <div className={styles.bottomBlocks}>
          <div className={styles.bottomBlock} id="purchase">
            <h1>Inköp</h1>
            <p>
              I Team Rexel får du löpande poäng för dina inköp*.
              Varje inköpskrona ger 0,2 poäng, d.v.s. att inköp för 10 000 kr ger dig 2 000 poäng.
              <ul>
                <li>1 000 kr ger 200 poäng</li>
                <li>10 000 kr ger 2 000 poäng</li>
                <li>50 000 kr ger 10 000 poäng</li>
                <li>100 000 kr ger 20 000 poäng</li>
              </ul>
              <span>*Gäller alla inköp från lager och i butik.
                Gäller ej anskaffningsköp och direktleverans.
              </span>
            </p>
          </div>
          <div className={styles.bottomBlock} id="education">
            <h1>Utbildningar hos Rexel</h1>
            <p>
              Att investera i kompetens utvecklar inte bara den enskilde medarbetaren utan
              även företaget. Vi kan arrangera kundanpassade utbildningar för ditt
              företagsbehov och önskemål.
            </p>
            <p>
              Mejla <a href="mailto:Rexelskolan@rexel.se">Rexelskolan@rexel.se</a> eller
              ring 08-556 214 31 för mer information.
            </p>
          </div>
          <div className={styles.bottomBlock} id="quiz">
            <h1>Quiz</h1>
            <p>
              Då och då lägger vi ut kunskapstester där du kan plocka hem extra
              poäng genom att svara på frågor om klubben, om Rexel eller om aktuella aktiviteter.
              Håll ögonen öppna för att tjäna extra poäng!
            </p>
            <p>Du får poäng för korrekta svar direkt efter att du besvarat frågorna i quizen.</p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default AboutView;
