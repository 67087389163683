/* @flow */

import type { Mode } from "state/view-mode";

import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useDevice from "helpers/use-device";
import usePrevious from "helpers/use-previous";
import cn from "classnames";
import { debounce } from "diskho";
import { CustomerData, QuoteData, ViewModeData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { setMode, MODE } from "state/view-mode";
import { canAffordCart } from "helpers/utils";
import { addMessage } from "state/messages";
import AppHeaderSmall from "components/AppHeader/Small";
import AppHeaderLarge from "components/AppHeader/Large";
import useOnFullMenuHiddenRoute from "helpers/use-on-full-menu-hidden-route";

import styles from "./styles.scss";

type Props = {
  className: string,
  subNavOpen: boolean,
  setSubNavOpen: boolean => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
};

const AppHeader = ({
  subNavOpen,
  setSubNavOpen,
  onHomeView,
  onCheckout,
  onSuccess,
  ...props
}: Props): React$Element<"header"> => {
  const sendMessage = useSendMessage();
  const isDesktop = useDevice("gt", 1239);
  const { location: { pathname } } = useHistory();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [lastVisited, setLastVisited] = useState<string | null>(null);
  const customerData = useData(CustomerData);
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const customer = customerData.state === "LOGGED_IN" ? customerData.data : null;
  const cartCount = quoteData.data ? quoteData.data.items.reduce((a, { qty }) => a + qty, 0) : 0;
  const {
    configuration,
    routes: { checkoutView, questionnaireView },
  } = useContext(StoreInfoContext);
  const viewMode = useData(ViewModeData);
  const onFullMenuHiddenRoute = useOnFullMenuHiddenRoute();
  const questionnaireViewUrl = questionnaireView && questionnaireView.url ? questionnaireView.url : "";

  const hasMobileSubNav = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ].some(route => pathname.includes(route));

  const [isScrolled, setIsScrolled] = useState(false);

  const setViewMode = useCallback((mode: Mode) => {
    sendMessage(setMode(mode));
  }, [sendMessage]);

  const setIsScrolledDebounced = useRef(debounce(() => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    }
    else {
      setIsScrolled(false);
    }
  }, 10)).current;

  useEffect(() => {
    if (checkoutView?.url && !pathname.includes(checkoutView.url)) {
      setLastVisited(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", setIsScrolledDebounced, { passive: true });

    return () => {
      window.removeEventListener("scroll", setIsScrolledDebounced);
    };
  }, [setIsScrolledDebounced]);

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, pathname]);

  // Open mini cart when adding to cart
  useEffect(() => {
    if (pathname === checkoutView?.url ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const canAfford = customer &&
      quoteData.data &&
      canAffordCart(quoteData.data);

    if (count > prevCount) {
      if (canAfford !== false) {
        setViewMode(MODE.CART);
      }
      else {
        sendMessage(addMessage("", "insufficient-funds"));
      }

      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, setSubNavOpen, prevQuoteData, quoteData, pathname]);

  return (
    <header
      className={cn(
        styles.container,
        { [styles.active]: isScrolled },
        { [styles.fullMenu]: !onHomeView },
        { [styles.empty]: onFullMenuHiddenRoute },
        { [styles.onHomeView]: onHomeView },
        { [styles.hasMobileSubNav]: hasMobileSubNav },
        { [styles.hamburgerOpen]: hamburgerOpen }
      )}
    >
      {isDesktop ?
        <AppHeaderLarge
          {...props}
          mode={viewMode}
          setMode={setViewMode}
          customer={customer}
          lastVisited={lastVisited}
          logoWidth={configuration.logoWidthLarge}
          isScrolled={isScrolled}
          onHomeView={onHomeView}
          onCheckout={onCheckout}
          onSuccess={onSuccess}
        /> :
        <AppHeaderSmall
          {...props}
          mode={viewMode}
          setMode={setViewMode}
          customer={customer}
          subNavOpen={subNavOpen}
          lastVisited={lastVisited}
          setSubNavOpen={setSubNavOpen}
          hamburgerOpen={hamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
          cartCount={cartCount}
          logoWidth={configuration.logoWidthSmall}
          isScrolled={isScrolled}
          onCheckout={onCheckout}
          onSuccess={onSuccess}
        />
      }
    </header>
  );
};

export default AppHeader;
