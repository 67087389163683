/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";

const FaqView = (): React$Node => {
  const { content: { faqView } } = useContext(StoreInfoContext);
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Helmet title={t("HEADER.FAQ")} />

      <Wrapper className={styles.wrapper}>
        <Breadcrumbs current={faqView.header ?? ""} />
        {faqView.header &&
          <Title>{faqView.header}</Title>
        }
        {/* eslint-disable react/no-danger */}
        {faqView.text &&
          <div
            className={styles.article}
            dangerouslySetInnerHTML={{ __html: faqView.text }}
          />
        }
        {/* eslint-enable react/no-danger */}
      </Wrapper>
    </div>
  );
};

export default FaqView;
