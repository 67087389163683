/* @flow */

import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { CustomerData } from "data";
import { Link } from "react-router-dom";
import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron-mini.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

export type Item = {
  name: string,
  url: string,
};

type ParentItem = {
  name: string,
  url: string,
  children: Array<Item>,
  awarditTargetId?: Array<number>,
};

type Props = {
  categories: Array<ParentItem>,
};

const Categories = ({ categories }: Props): React$Node => {
  const [open, setOpen] = useState(null);
  const listElement = useRef(null);
  const t = useTranslate();
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;

  useEffect(() => {
    if (!listElement.current) {
      return;
    }

    if (open !== null) {
      listElement.current.style.width = styles.categoriesWidth;
    }
    else {
      listElement.current.style.width = "0px";
    }
  }, [open]);

  return (
    <div className={cn("awardit-subMenuCategories", styles.block)}>
      <div className={cn(styles.parents)}>
        {categories
          .filter(item => !memberTargetList ||
          memberTargetList.includes(item.awarditTargetId) ||
          !item.awarditTargetId)
          .map(x => (
            <div key={x.url} className={cn(styles.row)}>
              <div className={cn("awardit-subMenuCategoriesItem", styles.item, { [styles.open]: open === x.url })}>
                <Link
                  className={styles.link}
                  to={{
                    pathname: x.url,
                    state: { hint: { type: "category", category: x } },
                  }}
                >
                  {x.name}
                </Link>
                {x.children.length > 0 &&
                  <button
                    type="button" className={styles.button} onClick={e => {
                      e.stopPropagation();
                      setOpen(open === x.url ? null : x.url);
                    }}
                  >
                    <ChevronIcon className={styles.chevron} />
                  </button>
                }
              </div>

              <Foldable
                key={x.url}
                className={cn(styles.children, { [styles.fade]: open })}
                open={open === x.url}
              >
                <div className={cn("awardit-subMenuCategoriesChildrenItem", styles.item)}>
                  <Link
                    className={styles.subLink}
                    to={{
                      pathname: x.url,
                      state: { hint: { type: "category", category: x } },
                    }}
                  >
                    {t("HEADER.CATEGORY_SEE_ALL", {
                      categoryName: `${x.name}`,
                    })}
                  </Link>
                </div>
                {x.children.map(c => (
                  <div key={c.url} className={cn("awardit-subMenuCategoriesChildrenItem", styles.item)}>
                    <Link
                      className={styles.subLink}
                      to={{
                        pathname: c.url,
                        state: { hint: { type: "category", category: c } },
                      }}
                    >
                      {c.name}
                    </Link>
                  </div>
                ))}
              </Foldable>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Categories;
